import Phaser from 'phaser'
// @ts-ignore
import skierImage from '../assets/skier.jpeg';

export default class SatseAppScene extends Phaser.Scene
{
    constructor()
	{
		super('satse-app')
	}

	preload()
    {
        this.load.image('skier', skierImage); 
    }

    create()
    {
        this.initialWidth = this.scale.width;
        this.initialHeight = this.scale.height;
        //this.matter.world.setBounds();

        /*const hill = '0 600 100 400 200 300 300 250 400 225 500 200 600 180 700 150 800 120 900 100 1000 100 1000 600';
        const poly = this.add.polygon(500, 400, hill, 0x0000ff);
        this.matter.add.gameObject(poly, { shape: { type: 'fromVerts', verts: hill, flagInternal: true }, isStatic: true  });*/

        this.skier = this.matter.add.sprite(80, -20, 'skier');
        this.skier.setFriction(0, 0, 0);

        this.cameras.main.startFollow(this.skier);

        

        const top = '0 0 200 2 200 20 0 20';
        const theTop = this.add.polygon(0, 0, top, 0xff0000);
        this.matter.add.gameObject(theTop, { shape: { type: 'fromVerts', verts: top, flagInternal: true }, isStatic: true  });

        const firstSlope = '0 0 20 0 400 600 380 600';
        const theFirstSlope = this.add.polygon(300, 300, firstSlope, 0x00ff00);
        this.matter.add.gameObject(theFirstSlope, { shape: { type: 'fromVerts', verts: firstSlope, flagInternal: true }, isStatic: true  });

        const jump = '0 0 20 0 120 100 220 150 320 150 320 170 220 170 120 120';
        const theJump = this.add.polygon(630, 700, jump, 0x0000ff);
        this.matter.add.gameObject(theJump, { shape: { type: 'fromVerts', verts: jump, flagInternal: true }, isStatic: true  });

        const endOfJump = '0 0 20 0 20 100 0 100';
        const theEndOfJump = this.add.polygon(780, 800, endOfJump, 0xff00ff);
        this.matter.add.gameObject(theEndOfJump, { shape: { type: 'fromVerts', verts: endOfJump, flagInternal: true }, isStatic: true  });

        const kulen = '0 0 400 0 400 20 0 20';
        const theKulen = this.add.polygon(980, 860, kulen, 0x00ffff);
        this.matter.add.gameObject(theKulen, { shape: { type: 'fromVerts', verts: kulen, flagInternal: true }, isStatic: true  });

        const unnarenn = '0 0 20 0 6020 10000 6000 10000';
        const theUnnarenn = this.add.polygon(4180, 5880, unnarenn, 0xffff00);
        this.matter.add.gameObject(theUnnarenn, { shape: { type: 'fromVerts', verts: unnarenn, flagInternal: true }, isStatic: true, label: 'unnarenn' });

        /*
        const bottomSlope = '0 0 20 0 120 100 220 150 320 150 320 170 220 170 120 120';
        const theBottomSlope = this.add.polygon(10000, 14000, bottomSlope, 0x00ff00);
        this.matter.add.gameObject(theBottomSlope, { shape: { type: 'fromVerts', verts: bottomSlope, flagInternal: true }, isStatic: true  });
        */
        this.isAlive = true;
        this.matter.world.on('collisionstart', function (event) {
            for (var i = 0; i < event.pairs.length; i++)
            {
                // TODO: Endre dette dersom kun ett polygon
                if (event.pairs[i].bodyA.label === 'unnarenn' || event.pairs[i].bodyB.label === 'unnarenn') {
                    this.isAlive = false;
                }
            }
        }, this);

        this.scoreLabel1 = this.add.text(10, 10, 'THIS 0', { fontSize: '32px', color: '#fff' }).setScrollFactor(0);
        const highScoreFromLocalStorage = localStorage.getItem('highScore');
        if (highScoreFromLocalStorage === null) {
            this.localHighScore = 0;
            localStorage.setItem('highScore', '0');
        } else {
            this.localHighScore = parseFloat(highScoreFromLocalStorage);
        }

        this.localHighScoreLabel = this.add.text(10, 40, 'BEST ' + this.localHighScore.toFixed(2), { fontSize: '32px', color: '#fff' }).setScrollFactor(0);

        this.actionsPerformed = 0;
        this.paused = false;
        const spaceKey = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);
        spaceKey.on('down', () => {
            if (Phaser.Input.Keyboard.JustDown(spaceKey)) {
                this.performAction();
            }
        });
        this.input.on('pointerdown', () => {
            this.performAction();
        });
        const rKey = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.R);
        rKey.on('down', () => {
            if (Phaser.Input.Keyboard.JustDown(rKey)) {
                this.scene.restart();
            }
        });
        const pKey = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.P);
        pKey.on('down', () => {
            if (Phaser.Input.Keyboard.JustDown(pKey)) {
                this.scene.pause();
                // TODO: Toggle pause scene such that resume is possible
            }
        });

        this.scale.on('resize', this.resize, this);
        const fKey = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.F);
        fKey.on('down', () => {
            if (Phaser.Input.Keyboard.JustDown(fKey)) {
                // TODO: Scalere spillområdet til å matceh fullskjermen
                if (this.scale.isFullscreen) {
                    this.scale.stopFullscreen();
                } else {
                    this.scale.startFullscreen();
                }
            }
        });
    }

    update() 
    {
        const jumpStart = 800;
        if (this.skier.x > jumpStart && this.isAlive) {
            const score = (this.skier.x - jumpStart) / 40;
            this.scoreLabel1.text = 'THIS ' + score.toFixed(2);
            if (score > this.localHighScore) {
                // TODO: Juice
                const highScore = score.toFixed(2);
                localStorage.setItem('highScore', highScore);
                this.localHighScore = score;
                this.localHighScoreLabel.text = 'BEST ' + highScore;
            }
        } else if (this.skier.y > 12000) {
            this.scene.restart();
        }
    }

    performAction() {
        if (this.actionsPerformed == 0) {
            this.skier.thrust(0.01);
         } else if (this.actionsPerformed == 1) {
            this.skier.thrustLeft(0.11);
         } else if (this.actionsPerformed == 2) {
             // TODO: 3 kan være landing. Time dette med tid fra faktisk landing skjedde og gi skåringer litt sånn rando fra dommere med juice  
         }

         this.actionsPerformed++;
    }

    resize(gameSize) {
        const width = gameSize.width;
        const height = gameSize.height;
        const scaleFactor = Math.min(width / this.initialWidth, height / this.initialHeight);
        this.cameras.main.setZoom(scaleFactor);
    
        // TODO:hvorfor flytter de seg litt?
        this.scoreLabel1.setPosition(10, 10);
        this.localHighScoreLabel.setPosition(10, 40);
      }
}
