import Phaser from 'phaser'

import SatseAppScene from './scenes/SatseAppScene'

const config = {
	type: Phaser.AUTO,
	scale: {
		mode: Phaser.Scale.RESIZE,
		parent: 'gameContainer',
		autoCenter: Phaser.Scale.CENTER_BOTH,
		width: 800,
		height: 600,
	},
	physics: {
		default: 'matter',
		matter: {
			gravity: {
			x: 0,
			y: 2
			},
			debug: true,
			debugBodyColor: Phaser.Display.Color.GetColor(255, 0, 0)
		}
	},
	scene: [SatseAppScene]
}

export default new Phaser.Game(config)
